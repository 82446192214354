





























































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";

import {
  BCard,
  BFormFile,
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
} from "bootstrap-vue";
import {
  AppEditor,
  AppInput,
  AppFilePreview,
  AppForm,
  AppSelect,
  AppButton,
  AppDatePicker,
} from "@/components/form/index";

import SeoWrapper from "@/components/Seo/SeoWrapper.vue";
import GalleryModal from "@/components/UI/GalleryModal.vue";
import { toFormData } from "@/utils/index";

@Component({
  name: "AddNewAward",
  components: {
    AppInput,
    SeoWrapper,
    GalleryModal,
    AppEditor,
    BCard,
    BFormGroup,
    BFormFile,
    AppFilePreview,
    AppForm,
    BFormCheckbox,
    AppSelect,
    BRow,
    BCol,
    AppButton,
    AppDatePicker,
  },
  data() {
    return {
      successMessage: null,
      id: "add-new-award",
      form: {
        name_ar: null,
        slug_ar: null,
        description_ar: null,
        terms_and_conditions: null,
        image: null,
        in_home: "0",
        is_active: "0",
        category: null,
        tags: [],
        brief_description_ar: null,
        year: null,
        result_date: null,
        apply_starts_at: null,
        apply_ends_at: null,
        judgment_starts_at: null,
        judgment_ends_at: null,
        seo: {},
      },
      // as Form
      types: [
        {
          label: this.$t("team") || "team",
          name_ar: this.$t("team") || "team",
          value: "team",
        },
        {
          label: this.$t("individual") || "individual",
          name_ar: this.$t("individual") || "individual",
          value: "individual",
        },
        {
          label: this.$t("no_apply") || "no_apply",
          name_ar: this.$t("no_apply") || "no_apply",
          value: "no apply",
        },
      ],
      seo_meta: {
        //
      },
    };
  },
  created() {
    this.$store.dispatch("awards/resetModule");
    this.$route.query &&
      this.$route.query.uuid &&
      this.$store.dispatch("awards/fetchSingleItem", this.$route.query.uuid);
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    today() {
      return new Date().toISOString().split("T")[0];
    },
    years() {
      const years = [];

      const currentYear = this.currentYear;
      for (let i = currentYear; i < currentYear + 50; i += 1) {
        years.push({
          id: i,
          value: i,
          label: i,
          name_ar: i,
        });
      }
      return years;
    },
    isLoading() {
      return (
        (this as any).$store.state.awards.isLoading.creating ||
        (this as any).$store.state.awards.isLoading.updating
      );
    },
    errorsBag() {
      return (this as any).$store.state.awards.errors;
    },
    singleData() {
      return (this as any).$store.state.awards.singleData;
    },
    doesErrorsExist() {
      return !!(
        // ((this as any).errorsBag &&
        //   Object.keys((this as any).errorsBag) &&
        //   Object.keys((this as any).errorsBag).length) ||
        (
          (this as any).errors &&
          (this as any).errors.items &&
          (this as any).errors.items.length
        )
      );
    },
  },
  watch: {
    singleData: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.form = newVal;
          this.seo_meta = newVal?.seo || {};
        }
      },
      deep: true,
    },
  },
  methods: {
    changeSeoHandler(key, value) {
      if (!key) return;
      (this as any).form["seo"][key] = value;
    },
    datepickerFilterHandler(name, date = null) {
      // console.log(
      //   "🚀 ~ file: add-new-award.vue ~ line 304 ~ datepickerFilterHandler ~ name, date",
      //   name,
      //   date
      // );
      // this.$set(this.filtersData, name, date);
      // this.filterHandler(this.filtersData);
    },
    selectImageHandler(image) {
      (this as any).form.image = image;
    },
    cancelHandler() {
      (this as any).$emit("cancel");
      (this as any).$router.push("/awards");
    },
    openGallaryModal() {
      (this as any).$bvModal.show("gallary-modal");
    },
    submitAndAddNewHandler() {
      (this as any).$emit("submit-and-add-new");
    },
    removeImageHandler() {
      (this as any).form.image = null;
    },
    handleSubmit() {
      const actionName = (this as any).singleData ? "updateData" : "createData";

      (this as any).$store
        .dispatch(`awards/${actionName}`, toFormData((this as any).form))
        .then(() => {
          (this as any).hideForm();
          (this as any).successMessage = (this as any).$t("successfully_saved");
          this.$router.push({ name: "awards" });
        });
    },
    hideForm() {
      (this as any).$bvModal.hide((this as any).id);
      (this as any).$store.dispatch("awards/resetModule");
      (this as any).form = {
        name_ar: null,
        slug_ar: null,
        description_ar: null,
        terms_and_conditions: null,
        image: null,
        in_home: "0",
        is_active: "0",
        category: null,
        tags: [],
        seo: {
          facebook_description_ar: null,
          facebook_image: null,
          facebook_title_ar: null,
          meta_description_ar: null,
          meta_focus_keyphrase_ar: null,
          meta_title_ar: null,
          twitter_description_ar: null,
          twitter_title_ar: null,
        },
        brief_description_ar: null,
        year: null,
        result_date: null,
        apply_starts_at: null,
        apply_ends_at: null,
        judgment_starts_at: null,
        judgment_ends_at: null,
      };
      (this as any).$emit("hide");
    },
  },
})
export default class AddNewaward extends Vue {
  // @Prop({ type: [String, Number], required: true }) id!: string | number;
  @Prop({ type: Object, required: false }) initialData: object;
}
